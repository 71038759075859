
import styled from 'styled-components';

export const NotFoundPageWrapper = styled.div`
  height: calc(100vh - 100px);
  background-color: #f1f3f4;
  padding: 2.5%;
  box-sizing: border-box;
  overflow-y: auto;
  text-align: center;

  img {
    width: 100%;
    max-width: 450px;
  }
`;

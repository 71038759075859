import React from 'react';

import { useTranslation } from 'react-i18next';
import Layout from '../components/MenuLayout';
import image from '../images/almacloud404.png';
import { NotFoundPageWrapper } from '../components/Common/NotFoundPageWrapper';

export default () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <NotFoundPageWrapper>
        <img alt="Not found" src={image} />
        <br />
        <b>{t('could.not.find.the.page')}</b>
      </NotFoundPageWrapper>
    </Layout>
  );
};
